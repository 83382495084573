.dvContainer {
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.1);
  padding: 3rem 1rem;
  height: fit-content;
  min-height: 26rem;
  p,
  span {
    margin-bottom: 0;
  }
  h5 {
    color: #09064f;
    font-weight: 600;
  }
  p {
    font-weight: 700;
  }
}

.ventas {
  color: #7f5416;
}
.comisiones {
  color: #16723d;
}
.entregar {
  color: #751121;
}
.lastPickup {
  margin-bottom: 1rem;
}

@media (min-width: 1600px) {
  .dvContainer {
    font-size: 1.2rem;
    p {
      font-size: 1.5rem;
    }
    span {
      font-size: 1.6rem;
    }
    h5 {
      font-size: 1.7rem;
    }
    h4 {
      font-size: 2.2rem;
    }
    min-height: 32rem;
  }
}