.cardB {
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  height: fit-content;
  min-height: 26rem;
  position: sticky;
  top: 3rem;
}
.card-imgB {
  background-image: url("../assets/secundario_color.png");
  background-size: contain;
  width: inherit;
  min-width: 6rem;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 5rem;
  background-repeat: no-repeat;
  padding-bottom: 3rem;
}
.card-infoB {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
}
.resume {
  width: 100%;
  padding: 0 1rem;
  display: grid;
  gap: 4px;
  p {
    margin-bottom: 0px;
  }
  h6 {
    color: #09064f;
    font-weight: 600;
  }
}
.resume-total {
  margin-top: 2rem;
  text-align: end;
  position: absolute;
  bottom: 0;
  right: 1rem;
  width: 85%;
  h4 {
    font-weight: 700;
  }
}
.text-titleB {
  text-transform: uppercase;
  font-size: 0.75em;
  color: #42caff;
  letter-spacing: 0.05rem;
}

.text-bodyB {
  font-size: 0.9rem;
  text-align: center;
  font-weight: 500;
  margin: 1rem 0;
}
.caja {
  width: 7rem;
  padding: 2rem 0;

}
.btnPurpleSmall {
  background-color: #6533ff;
  color: white;
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 3px;
}

@media (max-width: 1200px) {
  .cardB {
    width: 18rem;
    height: 20rem;
  }
  .text-bodyB {
    font-size: 0.7rem;
  }
}


@media (min-width: 1600px) {
  .cardB {
    min-height: 34rem;
  }
  .text-bodyB {
    font-size: 1.2rem;
  }
  .resume {
    p {
      font-size: 1.3rem;
    }
    h6 {
      font-size: 1.6rem;
    }
    h4 {
      font-size: 1.9rem;
    }
  }
}
