.logoPrivacity {
  width: 10rem;
}
.container-Privacity {
  width: 80%;
  margin: 6rem auto;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  background-color: #b29ffc5e;
  padding: 1rem;
  .logoPrivacity{
    display: block;
 margin: 0px auto;
  }
  h4 {
    text-align: center;
    color: #6533ff;
    font-weight: 600;
    margin: 1.5rem;
    text-shadow: 0.1em 0.01em rgba(51, 51, 51, 0.182);
  }
  h6 {
    color: #6533ff;
  }
  p {
    padding: 1rem;
    color: #252525;
  }
}
.return{
    color:#6533ff;
    text-decoration: none;
    margin:5rem 1rem;
}
@media (max-width: 1200px) {
    .container-Privacity {
        width: 100%;
    margin: 0;}
    .return{
      
        margin:2rem ;
    }
    .logoPrivacity {
        width: 8rem;
        margin: 2rem auto !important;
      }
      p {
        text-align: justify;
      }
}