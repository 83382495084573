#mainNavbar {
  z-index: 100 !important;
  background-color: #6533ff;
}
#collapseNavMenu {
  width: inherit;
}
.container-fluid {
  height: 5rem;
}
.logoIcon {
  width: 3.75rem;
  margin: 0.75rem 2.8rem;
}
.ulStyle {
  display: flex;
  background-color: rgb(137, 137, 44);
  margin: 0.5rem;
}
.li-header {
  font-weight: 500;
  min-width: 10%;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  button {
    font-family: "Montserrat", sans-serif;
    font-size: larger;
  }
}
.li-header :hover {
  border-bottom: 4px solid #ef2b43;
}
.li-header :active .li-header ::after {
  border-bottom: 4px solid #ef2b43;
}
.li-header :active {
  border-bottom: 4px solid #ef2b43;
}
.li-headerClose {
  margin-right: 1%;
  font-weight: 500;
  font-size: 1rem;
  min-width: 20%;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: end;
  float: right !important;
  .btnClose {
    border: none;
    background-color: #6533ff;
    color: #ffffff;
    opacity: 1;
    text-align: center;
    margin-top: 0.5rem;
    font-family: "Montserrat", sans-serif;
    font-size: larger;
  }
  .btnClose:hover {
    color: #b19ffc;
    text-shadow: black 0.1em 0.1em 0.2em;
    font-size: 1.1rem;
  }
}

.li-headerSearch {
  margin: 0 5rem;
  font-weight: 500;
  font-size: 1rem;
  width: inherit;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: start;
  align-self: center;
  input,
  li {
    font-family: "Montserrat", sans-serif;
  }
}
.inputSearch {
  background-color: #b19ffc !important;
  border: none !important;
  padding: 0.5rem !important;
  height: 2rem !important;
  box-shadow: none !important;
  color: white !important;
}
.selectedOption {
  button {
    border-bottom: 3px solid #ef2b43;
  }
}

@media (max-width: 1200px) {
  .container-fluid {
    height: 5rem;
  }
  ul {
    background-color: #6533ff;
  }
  .li-header {
    text-align: start;
  }
  .li-headerClose {
    font-weight: 500;
    font-size: 1rem;
    min-width: 8rem;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    text-align: start;
  }
  .li-headerSearch {
    margin: 0 0 0 1.3rem;
  }
}
@media (min-width: 1600px) {
  .li-header {
    font-size: 1.4rem;
  }
  .li-headerClose {
    font-size: 1.4rem;
  }
  .li-headerSearch {
    font-size: 1.4rem;
  }
}
