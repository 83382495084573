.containerBtns {
  display: flex;
  flex-direction: row;
  align-content: center;
  font-family: "Montserrat", sans-serif;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
}
.cta {
  border: none;
  background: none;
  margin: 0 0.6rem;
}

.cta span {
  padding-bottom: 7px;
  font-size: 1.3rem;
  letter-spacing: 0px;
  color: #09064f;
  opacity: 1;
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active svg {
  transform: scale(0.9);
}

.hover-animation {
  position: relative;
  color: #6533ff;
  padding-bottom: 20px;
}
.selected {
  border-bottom: 3px solid #6533ff;
}

.hover-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #6533ff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  opacity: 1;
}

.cta:hover .hover-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
@media (max-width: 1200px) {
  .containerBtns {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 1.5rem 0;
  }
  .cta {
    margin: 0.5rem;
  }
}

@media (min-width: 1600px) {
  .containerBtns {
    span {
      font-size: 1.6rem;
    }
  }
}


@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
