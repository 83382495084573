.container-products {
  margin-top: 3rem;
}
.styles2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.styles {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(7.6rem, 1fr));
}
.MuiChip-root {
  font-family: "Montserrat", sans-serif !important;
  font-size: 1.1rem !important;
}
.infoBox {
  margin-left: 2rem;
}

@media (min-width: 1600px) {
  .MuiChip-root {
    font-size: 1.5rem !important;
  }
}
