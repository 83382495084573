.reports {
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  min-height: 26rem;
  height: fit-content;
}

.select_info {
  padding-left: 63px;
  width: 100%;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.dataBlockItem {
  display: flex;
}
.dataBlockItem label {
  width: 32%;
  padding: 0 !important;
}
.dataBlockItem p {
  width: 55% !important;
  padding: 0 !important;
  margin-bottom: 0.3rem;
}
