.spinner {
  overflow-x: hidden;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  top: 0;
}
.spinner > div > div {
  position: absolute;
  left: 50%;
  top: 25%;
}

.spinner > div > div > svg {
  width: 120px;
  height: 120px;
}
